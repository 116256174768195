import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import store from './store'
import './assets/tailwind.css'
import { io } from 'socket.io-client'
import Notifications from '@kyvg/vue3-notification'
const app = createApp(App)
app.config.globalProperties.$soket = io({ transports: ['polling', 'websocket', 'webtransport'], upgrade: true, reconnection: true, forceNew: false, pingTimeout: 60000 })
app.use(Notifications).use(router).use(store).mount('#app')
