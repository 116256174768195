import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/lobby',
    name: 'Lobby',
    component: () => import('../views/lobby.vue')
  },
  {
    path: '/table/:id',
    name: 'Table',
    component: () => import('../views/table.vue')
  },
  {
    path: '/confirm-email',
    name: 'Confirm-Email',
    component: () => import('../views/confirmEmail.vue')
  },
  {
    path: '/confirm-email-link/:token',
    name: 'confirm-email-link',
    component: () => import('../views/verifyEmailToken.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import('../views/feed.vue')
  },
  {
    path: '/display-feed',
    name: 'Display-Feed',
    component: () => import('../views/displayfeed.vue')
  },
  {
    path: '/faucet',
    name: 'Faucet',
    component: () => import('../views/faucet.vue')
  },
  {
    path: '/connect',
    name: 'Connect',
    component: () => import('../views/connect.vue')
  },
  {
    path: '/request-reset-password',
    name: 'Request-Reset-Password',
    component: () => import('../views/requestResetPassword.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'Reset-Password',
    component: () => import('../views/resetPassword.vue')
  },
  {
    path: '/signup-info',
    name: 'Signup-Info',
    component: () => import('../views/signupinfo.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/profile.vue')
  },
  {
    path: '/payment-success/:id',
    name: 'Payment-Success',
    component: () => import('../views/paymentSuccess.vue')
  },
  {
    path: '/payment-success-chips/:id',
    name: 'Payment-Success-Chips',
    component: () => import('../views/paymentSuccessChips.vue')
  },
  {
    path: '/payment-failed',
    name: 'Payment-Failed',
    component: () => import('../views/paymentFailed.vue')
  },
  {
    path: '/onboard-creator-success/:id',
    name: 'Onboard-Creator-Success',
    component: () => import('../views/onboardSuccess.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/error.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
