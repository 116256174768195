import axios from 'axios'
import xss from 'xss'

const state = {
    ethAddress: null,
}

const getters = {
    
}

const actions = {
    Property: async ({ state, commit }, ethAddress) => {
        commit('setProperty', ethAddress)
    },
}

const mutations = {
    setProperty: (state, ethAddress) => {
        state.ethAddress = ethAddress
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
