import axios from 'axios'

const state = {
    passwordResetDone: null,
    sendConfirmEmailDone: null,
    sendPasswordResetEmailDone: null,
}
const getters = {
    passwordResetDone: state => state.passwordResetDone,
    sendConfirmEmailDone: state => state.sendConfirmEmailDone,
    sendPasswordResetEmailDone: state => state.sendPasswordResetEmailDone,
}

const actions = {
    sendConfirmEmail: async ({ commit }, token) => {
        try {
            await axios({
                method: 'post',
                url: '/send-confirm-email',
                data: { token: token },
            })
            commit('setSendConfirmEmailDone', true)
        } catch (error) {
            commit('setSendConfirmEmailDone', false)
        }
    },
    sendPasswordResetEmail: async ({ commit }, email) => {
        console.log('send reset email')
        try {
            await axios({
                method: 'post',
                url: '/send-password-reset-email',
                data: { email: email },
            })
            commit('setSendPasswordResetEmailDone', true)
        } catch (error) {
            console.log(error)
            commit('setSendPasswordResetEmailDone', false)
        }
    },
    resetPassword: async ({ commit }, args) => {
        try {
            await axios({
                method: 'post',
                url: '/reset-password',
                headers: { 'x-access-token': args.token },
                data: { password: args.password },
            })
            commit('setPasswordResetDone', true)
        } catch (error) {
            console.log(error)
            commit('setPasswordResetDone', false)
        }
    },
}

const mutations = {
    setPasswordResetDone: (state, result) => {
        state.passwordResetDone = result
    },
    setSendConfirmEmailDone: (state, result) => {
        state.sendConfirmEmailDone = result
    },
    setSendPasswordResetEmailDone: (state, result) => {
        state.sendPasswordResetEmailDone = result
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
