import axios from 'axios'
import { uniqBy } from 'lodash'

const state = {
  faucets: []
}

const getters = {
  faucets: state => state.faucets
}

const actions = {

  getFaucets: async ({ commit, state }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/get-faucets',
        headers: { 'x-access-token': args.token },
        data: { page: args.page }
      })
      if (response.data.docs.length > 0) {
        commit('getFaucets', response.data.docs)
      }
    } catch (error) {
      console.log(error)
    }
  },

  searchFaucets: async ({ commit }, args) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/search-faucets',
        headers: { 'x-access-token': args.token },
        data: { searchTerm: args.searchTerm }
      })
      commit('getFaucets', response.data)
    } catch (error) {
      commit('getFaucets', [])
    }
  },

  faucetView: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/faucet-view',
        headers: { 'x-access-token': args.token },
        data: {
          userId: args.userId,
          faucetId: args.faucetId
        }
      })
    } catch (error) {
      console.log(error)
    }
  },

  likeFaucet: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/like-faucet',
        headers: { 'x-access-token': args.token },
        data: {
          userId: args.userId,
          faucetId: args.faucetId
        }
      })
      const response = await axios({
        method: 'post',
        url: '/get-faucets',
        headers: { 'x-access-token': args.token },
        data: {}
      })
      commit('getFaucets', response.data)
    } catch (error) {
      console.log(error)
    }
  },

  faucetClaim: async ({ commit }, args) => {
    try {
      await axios({
        method: 'post',
        url: '/faucet-claim',
        headers: { 'x-access-token': args.token },
        data: {
          userId: args.userId,
          faucetId: args.faucetId
        }
      })
      const response = await axios({
        method: 'post',
        url: '/get-faucets',
        headers: { 'x-access-token': args.token },
        data: {}
      })
      commit('getFaucets', response.data)
    } catch (error) {
      console.log(error)
    }
  }
}

const mutations = {
  getFaucets: (state, faucets) => {
    let newPosts = []
    newPosts.push(state.faucets)
    newPosts.push(faucets)
    const unique = [...new Map(newPosts.flat().map(item => [item['_id'], item])).values()]
    state.faucets = unique
  },

  searchFaucets: (state, faucets) => {
    state.faucets = faucets
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
