import { createStore } from 'vuex'
import feed from './modules/feed'
import ethereum from './modules/ethereum'
import utility from './modules/utility'
import faucet from './modules/faucet'
import profile from './modules/profile'

export default createStore({
  modules: {
    feed,
    ethereum,
    utility,
    faucet,
    profile
  }
})
